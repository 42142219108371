import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:cidade/influencer/:obrigado?',
    name: 'influencer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/influencer.vue')
  },
  {
    path: '/:cidade/loja/:obrigado?',
    name: 'loja',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/loja.vue')
  },
  {
    path: '/:cidade/influencer/obrigado',
    name: 'influencer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/influencer.vue')
  },
  
  {
    path: '/:cidade/cafezinho/:obrigado?',
    name: 'cafezinho',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/cafezinho.vue')
  },
  {
    path: '/:cidade/cafezinho/obrigado',
    name: 'cafezinho',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/cafezinho.vue')
  },
  {
    path: '/:cidade/lp-video/:obrigado?',
    name: 'lp-video',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/lp-video.vue')
  },
  {
    path: '/:cidade/lp-video/obrigado',
    name: 'lp-video',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/lp-video.vue')
  },
  {
    path: '/:cidade/empresarial/:obrigado?',
    name: 'empresarial',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/empresarial.vue')
  },
  {
    path: '/:cidade/empresarial/obrigado',
    name: 'empresarial',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/empresarial.vue')
  },
  {
    path: '/:cidade/lp/:obrigado?',
    name: 'lp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/lp.vue')
  }
  ,
  {
    path: '/:cidade/lp/obrigado',
    name: 'lp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/lp.vue')
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

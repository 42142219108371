<template>
  <v-app>


    <v-dialog v-model="dialog" max-width="490">
      <v-card>
        <v-card-title class="text-h5"
          style=" font-family: sofia pro !important; font-weight: 500; font-size: 27px !important; color: #1b3765; line-height: 1; text-align: center; word-break: inherit;">
          Fale conosoco e tire suas dúvidas</v-card-title>

        <div style="background-color: #fff; border-radius: 10px; padding: 25px">
          <v-text-field outlined v-model="contato.nome" label="Nome" required>
          </v-text-field>
          <v-text-field outlined v-model="contato.email" label="E-mail" required></v-text-field>
          <v-text-field v-mask="'(##) #####-####'" outlined v-model="contato.telefone" :counter="15" label="Telefone"
            required>
          </v-text-field>
          <v-text-field v-mask="'###.###.###-##'" outlined v-model="contato.cpf" :counter="14" label="CPF" required>
          </v-text-field>
          <v-select :items="items" v-model="contato.orgao" label="Órgão" outlined></v-select>
          <v-btn @click="enviarFormulario" block class="mr-4" type="submit" style="
              background-color: #629e42;
              color: #fff;
              font-family: Sofia pro;
              font-weight: 300;
              border-radius: 5px;
              text-transform: inherit;
            ">
            Enviar
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="politica">
      <v-card style="padding: 20px">
        <v-row style="display: flex; justify-items: end; flex-direction: column; align-items: end;">
          <v-icon @click="politica = false">mdi-close</v-icon>
        </v-row>
        <v-card-title class="text-h5"
          style="font-weight: 500; font-size: 27px !important; color: #1b3765; line-height: 1; text-align: center; word-break: inherit;">
          Pol&iacute;tica de Privacidade</v-card-title>
      
        <p
          style="box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; font-size: 16px; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          A sua privacidade &eacute; importante para n&oacute;s. &Eacute; pol&iacute;tica do Cartão Beneficiar respeitar
          a sua privacidade em rela&ccedil;&atilde;o a qualquer informa&ccedil;&atilde;o sua que possamos coletar no
          site <a href="cbeneficiar.com">Cartão Beneficiar</a>, e outros sites que possu&iacute;mos e operamos.</p>
        <p
          style="box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; font-size: 16px; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          Solicitamos informa&ccedil;&otilde;es pessoais apenas quando realmente precisamos delas para lhe fornecer um
          servi&ccedil;o. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Tamb&eacute;m
          informamos por que estamos coletando e como ser&aacute; usado.</p>
        <p
          style="box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; font-size: 16px; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          Apenas retemos as informa&ccedil;&otilde;es coletadas pelo tempo necess&aacute;rio para fornecer o
          servi&ccedil;o solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente
          aceit&aacute;veis   para evitar perdas e roubos, bem como acesso, divulga&ccedil;&atilde;o, c&oacute;pia, uso
          ou modifica&ccedil;&atilde;o n&atilde;o autorizados.</p>
        <p
          style="box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; font-size: 16px; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          N&atilde;o compartilhamos informa&ccedil;&otilde;es de identifica&ccedil;&atilde;o pessoal publicamente ou com
          terceiros, exceto quando exigido por lei.</p>
        <p
          style="box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; font-size: 16px; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          O nosso site pode ter links para sites externos que n&atilde;o s&atilde;o operados por n&oacute;s. Esteja
          ciente de que n&atilde;o temos controle sobre o conte&uacute;do e pr&aacute;ticas desses sites e n&atilde;o
          podemos aceitar responsabilidade por suas respectivas&nbsp;<a
            style="box-sizing: border-box; color: #055af9; text-decoration-line: none; background-color: transparent;"
            href="https://politicaprivacidade.com/" target="_BLANK">pol&iacute;ticas de privacidade</a>.</p>
        <p
          style="box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; font-size: 16px; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          Voc&ecirc; &eacute; livre para recusar a nossa solicita&ccedil;&atilde;o de informa&ccedil;&otilde;es
          pessoais, entendendo que talvez n&atilde;o possamos fornecer alguns dos servi&ccedil;os desejados.</p>
        <p
          style="box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; font-size: 16px; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          O uso continuado de nosso site ser&aacute; considerado como aceita&ccedil;&atilde;o de nossas pr&aacute;ticas
          em torno de <a href='https://avisodeprivacidad.info/' rel='nofollow' target='_BLANK'
            style='color:inherit !important; text-decoration: none !important; font-size: inherit !important;'>Aviso de
            Privacidad</a> e informa&ccedil;&otilde;es pessoais. Se voc&ecirc; tiver alguma d&uacute;vida sobre como
          lidamos com dados do usu&aacute;rio e informa&ccedil;&otilde;es pessoais, entre em contacto connosco.</p>
        <p
          style="box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; font-size: 16px; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
        <h2
          style="box-sizing: border-box; margin: 0px; line-height: 1.2; font-size: 20px; letter-spacing: -0.05em; color: #576d96; padding-bottom: 20px; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          Pol&iacute;tica de Cookies Cartão Beneficiar</h2>
        <h3
          style="box-sizing: border-box; margin: 0px 0px 20px; line-height: 1.2; font-size: 16px; letter-spacing: -0.05em; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          O que s&atilde;o cookies?</h3>
        <p
          style="font-size: 16px; box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          Como &eacute; pr&aacute;tica comum em quase todos os sites profissionais, este site usa cookies, que
          s&atilde;o pequenos arquivos baixados no seu computador, para melhorar sua experi&ecirc;ncia. Esta
          p&aacute;gina descreve quais informa&ccedil;&otilde;es eles coletam, como as usamos e por que &agrave;s vezes
          precisamos armazenar esses cookies. Tamb&eacute;m compartilharemos como voc&ecirc; pode impedir que esses
          cookies sejam armazenados, no entanto, isso pode fazer o downgrade ou 'quebrar' certos elementos da
          funcionalidade do site.</p>
        <h3
          style="box-sizing: border-box; margin: 0px 0px 20px; line-height: 1.2; font-size: 16px; letter-spacing: -0.05em; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          Como usamos os cookies?</h3>
        <p
          style="font-size: 16px; box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          Utilizamos cookies por v&aacute;rios motivos, detalhados abaixo. Infelizmente, na maioria dos casos,
          n&atilde;o existem op&ccedil;&otilde;es padr&atilde;o do setor para desativar os cookies sem desativar
          completamente a funcionalidade e os recursos que eles adicionam a este site. &Eacute; recomend&aacute;vel que
          voc&ecirc; deixe todos os cookies se n&atilde;o tiver certeza se precisa ou n&atilde;o deles, caso sejam
          usados   para fornecer um servi&ccedil;o que voc&ecirc; usa.</p>
        <h3
          style="box-sizing: border-box; margin: 0px 0px 20px; line-height: 1.2; font-size: 16px; letter-spacing: -0.05em; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          Desativar cookies</h3>
        <p
          style="font-size: 16px; box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          Voc&ecirc; pode impedir a configura&ccedil;&atilde;o de cookies ajustando as configura&ccedil;&otilde;es do
          seu navegador (consulte a Ajuda do navegador para saber como fazer isso). Esteja ciente de que a
          desativa&ccedil;&atilde;o de cookies afetar&aacute; a funcionalidade deste e de muitos outros sites que
          voc&ecirc; visita. A desativa&ccedil;&atilde;o de cookies geralmente resultar&aacute; na
          desativa&ccedil;&atilde;o de determinadas funcionalidades e recursos deste site. Portanto, &eacute;
          recomend&aacute;vel que voc&ecirc; n&atilde;o desative os cookies.</p>
        <h3
          style="box-sizing: border-box; margin: 0px 0px 20px; line-height: 1.2; font-size: 16px; letter-spacing: -0.05em; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          Cookies que definimos</h3>
        <ul
          style="font-size: 16px; box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          <li style="box-sizing: border-box;">Cookies relacionados &agrave; conta<br
              style="box-sizing: border-box;" /><br style="box-sizing: border-box;" />Se voc&ecirc; criar uma conta
            connosco, usaremos cookies para o gerenciamento do processo de inscri&ccedil;&atilde;o e
            administra&ccedil;&atilde;o geral. Esses cookies geralmente ser&atilde;o exclu&iacute;dos quando voc&ecirc;
            sair do sistema, por&eacute;m, em alguns casos, eles poder&atilde;o permanecer posteriormente para lembrar
            as prefer&ecirc;ncias do seu site ao sair.<br style="box-sizing: border-box;" /><br
              style="box-sizing: border-box;" /></li>
          <li style="box-sizing: border-box;">Cookies relacionados ao login<br style="box-sizing: border-box;" /><br
              style="box-sizing: border-box;" />Utilizamos cookies quando voc&ecirc; est&aacute; logado, para que
            possamos lembrar dessa a&ccedil;&atilde;o. Isso evita que voc&ecirc; precise fazer login sempre que visitar
            uma nova p&aacute;gina. Esses cookies s&atilde;o normalmente removidos ou limpos quando voc&ecirc; efetua
            logout para garantir que voc&ecirc; possa acessar apenas a recursos e &aacute;reas restritas ao efetuar
            login.<br style="box-sizing: border-box;" /><br style="box-sizing: border-box;" /></li>
          <li style="box-sizing: border-box;">Cookies relacionados a boletins por e-mail<br
              style="box-sizing: border-box;" /><br style="box-sizing: border-box;" />Este site oferece servi&ccedil;os
            de assinatura de boletim informativo ou e-mail e os cookies podem ser usados   para lembrar se voc&ecirc;
            j&aacute; est&aacute; registrado e se deve mostrar determinadas notifica&ccedil;&otilde;es v&aacute;lidas
            apenas para usu&aacute;rios inscritos / n&atilde;o inscritos.<br style="box-sizing: border-box;" /><br
              style="box-sizing: border-box;" /></li>
          <li style="box-sizing: border-box;">Pedidos processando cookies relacionados<br
              style="box-sizing: border-box;" /><br style="box-sizing: border-box;" />Este site oferece facilidades de
            com&eacute;rcio eletr&ocirc;nico ou pagamento e alguns cookies s&atilde;o essenciais para garantir que seu
            pedido seja lembrado entre as p&aacute;ginas, para que possamos process&aacute;-lo adequadamente.<br
              style="box-sizing: border-box;" /><br style="box-sizing: border-box;" /></li>
          <li style="box-sizing: border-box;">Cookies relacionados a pesquisas<br style="box-sizing: border-box;" /><br
              style="box-sizing: border-box;" />Periodicamente, oferecemos pesquisas e question&aacute;rios para
            fornecer informa&ccedil;&otilde;es interessantes, ferramentas &uacute;teis ou para entender nossa base de
            usu&aacute;rios com mais precis&atilde;o. Essas pesquisas podem usar cookies para lembrar quem j&aacute;
            participou numa pesquisa ou para fornecer resultados precisos ap&oacute;s a altera&ccedil;&atilde;o das
            p&aacute;ginas.<br style="box-sizing: border-box;" /><br style="box-sizing: border-box;" /></li>
          <li style="box-sizing: border-box;">Cookies relacionados a formul&aacute;rios<br
              style="box-sizing: border-box;" /><br style="box-sizing: border-box;" />Quando voc&ecirc; envia dados por
            meio de um formul&aacute;rio como os encontrados nas p&aacute;ginas de contacto ou nos formul&aacute;rios de
            coment&aacute;rios, os cookies podem ser configurados para lembrar os detalhes do usu&aacute;rio para
            correspond&ecirc;ncia futura.<br style="box-sizing: border-box;" /><br style="box-sizing: border-box;" />
          </li>
          <li style="box-sizing: border-box;">Cookies de prefer&ecirc;ncias do site<br
              style="box-sizing: border-box;" /><br style="box-sizing: border-box;" />Para proporcionar uma &oacute;tima
            experi&ecirc;ncia neste site, fornecemos a funcionalidade para definir suas prefer&ecirc;ncias de como esse
            site &eacute; executado quando voc&ecirc; o usa. Para lembrar suas prefer&ecirc;ncias, precisamos definir
            cookies para que essas informa&ccedil;&otilde;es possam ser chamadas sempre que voc&ecirc; interagir com uma
            p&aacute;gina for afetada por suas prefer&ecirc;ncias.</li>
        </ul>
        <h3
          style="box-sizing: border-box; margin: 0px 0px 20px; line-height: 1.2; font-size: 16px; letter-spacing: -0.05em; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          Cookies de Terceiros</h3>
        <p
          style="font-size: 16px; box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          Em alguns casos especiais, tamb&eacute;m usamos cookies fornecidos por terceiros confi&aacute;veis. A
          se&ccedil;&atilde;o a seguir detalha quais cookies de terceiros voc&ecirc; pode encontrar atrav&eacute;s deste
          site.</p>
        <ul
          style="font-size: 16px; box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          <li style="box-sizing: border-box;">Este site usa o Google Analytics, que &eacute; uma das
            solu&ccedil;&otilde;es de an&aacute;lise mais difundidas e confi&aacute;veis   da Web, para nos ajudar a
            entender como voc&ecirc; usa o site e como podemos melhorar sua experi&ecirc;ncia. Esses cookies podem
            rastrear itens como quanto tempo voc&ecirc; gasta no site e as p&aacute;ginas visitadas, para que possamos
            continuar produzindo conte&uacute;do atraente.</li>
        </ul>
        <p
          style="font-size: 16px; box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          Para mais informa&ccedil;&otilde;es sobre cookies do Google Analytics, consulte a p&aacute;gina oficial do
          Google Analytics.</p>
        <ul
          style="font-size: 16px; box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          <li style="box-sizing: border-box;">As an&aacute;lises de terceiros s&atilde;o usadas para rastrear e medir o
            uso deste site, para que possamos continuar produzindo conte&uacute;do atrativo. Esses cookies podem
            rastrear itens como o tempo que voc&ecirc; passa no site ou as p&aacute;ginas visitadas, o que nos ajuda a
            entender como podemos melhorar o site para voc&ecirc;.</li>
          <li style="box-sizing: border-box;">Periodicamente, testamos novos recursos e fazemos altera&ccedil;&otilde;es
            subtis na maneira como o site se apresenta. Quando ainda estamos testando novos recursos, esses cookies
            podem ser usados   para garantir que voc&ecirc; receba uma experi&ecirc;ncia consistente enquanto estiver no
            site, enquanto entendemos quais otimiza&ccedil;&otilde;es os nossos usu&aacute;rios mais apreciam.</li>
          <li style="box-sizing: border-box;">&Agrave; medida que vendemos produtos, &eacute; importante entendermos as
            estat&iacute;sticas sobre quantos visitantes de nosso site realmente compram e, portanto, esse &eacute; o
            tipo de dados que esses cookies rastrear&atilde;o. Isso &eacute; importante para voc&ecirc;, pois significa
            que podemos fazer previs&otilde;es de neg&oacute;cios com precis&atilde;o que nos permitem analizar nossos
            custos de publicidade e produtos para garantir o melhor pre&ccedil;o poss&iacute;vel.</li>
        </ul>
        <p>
        <ul
          style="box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; color: #576d96; font-family: Montserrat, sans-serif; font-size: 16px; background-color: #ffffff;">
          <li style="box-sizing: border-box;">O servi&ccedil;o Google AdSense que usamos para veicular publicidade usa
            um cookie DoubleClick para veicular an&uacute;ncios mais relevantes em toda a Web e limitar o n&uacute;mero
            de vezes que um determinado an&uacute;ncio &eacute; exibido para voc&ecirc;.<br
              style="box-sizing: border-box;" />Para mais informa&ccedil;&otilde;es sobre o Google AdSense, consulte as
            FAQs oficiais sobre privacidade do Google AdSense.</li>
          <li style="box-sizing: border-box;">Utilizamos an&uacute;ncios para compensar os custos de funcionamento deste
            site e fornecer financiamento para futuros desenvolvimentos. Os cookies de publicidade comportamental usados
              por este site foram projetados para garantir que voc&ecirc; forne&ccedil;a os an&uacute;ncios mais
            relevantes sempre que poss&iacute;vel, rastreando anonimamente seus interesses e apresentando coisas
            semelhantes que possam ser do seu interesse.</li>
          <li style="box-sizing: border-box;">V&aacute;rios parceiros anunciam em nosso nome e os cookies de
            rastreamento de afiliados simplesmente nos permitem ver se nossos clientes acessaram o site atrav&eacute;s
            de um dos sites de nossos parceiros, para que possamos credit&aacute;-los adequadamente e, quando
            aplic&aacute;vel, permitir que nossos parceiros afiliados ofere&ccedil;am qualquer promo&ccedil;&atilde;o
            que pode fornec&ecirc;-lo para fazer uma compra.</li>
        </ul>
        </p>
        <h3
          style="box-sizing: border-box; margin: 0px 0px 20px; line-height: 1.2; font-size: 16px; letter-spacing: -0.05em; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          Compromisso do Usu&aacute;rio</h3>
        <p
          style="box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; font-size: 16px; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          O usu&aacute;rio se compromete a fazer uso adequado dos conte&uacute;dos e da informa&ccedil;&atilde;o que o
          Cartão Beneficiar oferece no site e com car&aacute;ter enunciativo, mas n&atilde;o limitativo:</p>
        <ul
          style="box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; color: #576d96; font-family: Montserrat, sans-serif; font-size: 16px; background-color: #ffffff;">
          <li style="box-sizing: border-box;">A) N&atilde;o se envolver em atividades que sejam ilegais ou
            contr&aacute;rias &agrave; boa f&eacute; a &agrave; ordem p&uacute;blica;</li>
          <li style="box-sizing: border-box;">B) N&atilde;o difundir propaganda ou conte&uacute;do de natureza racista,
            xenof&oacute;bica, <a href='https://ondeapostar.com/betano-e-confiavel' rel='nofollow' target='_BLANK'
              style='color:inherit !important; text-decoration: none !important; font-size: inherit !important;'>onde
              apostar</a> ou azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos
            humanos;</li>
          <li style="box-sizing: border-box;">C) N&atilde;o causar danos aos sistemas f&iacute;sicos (hardwares) e
            l&oacute;gicos (softwares) do Cartão Beneficiar, de seus fornecedores ou terceiros, para introduzir ou
            disseminar v&iacute;rus inform&aacute;ticos ou quaisquer outros sistemas de hardware ou software que sejam
            capazes de causar danos anteriormente mencionados.</li>
        </ul>
        <h3
          style="box-sizing: border-box; margin: 0px 0px 20px; line-height: 1.2; font-size: 16px; letter-spacing: -0.05em; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          Mais informa&ccedil;&otilde;es</h3>
        <p
          style="box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; font-size: 16px; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que voc&ecirc; n&atilde;o
          tem certeza se precisa ou n&atilde;o, geralmente &eacute; mais seguro deixar os cookies ativados, caso
          interaja com um dos recursos que voc&ecirc; usa em nosso site.</p>
        <p
          style="box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; font-size: 16px; color: #576d96; font-family: Montserrat, sans-serif; background-color: #ffffff;">
          Esta pol&iacute;tica &eacute; efetiva a partir de <strong>19 agosto 2022 17:41</strong></p>
      </v-card>
    </v-dialog>

    <v-main class="">
      <router-view />
    </v-main>

    <v-row v-if="!isLp" justify="center" align="center" style="background-color:#EF7C00; margin-top: 0px; padding: 10px 10px;     margin: 0 0px !important;">
      <v-col cols="12" md="5" class="alinhadoM">
        <p style="margin-bottom: 0px !important; color: #fff">Cartão Beneficiar – Todos direitos reservados. Site atualizado em 2022.</p>
      </v-col>

      <v-col cols="12" md="3" class="alinhadoM">
        <v-btn @click="politica = true" style="box-shadow: none !important; background-color: transparent !important; color: #fff">
          Politica de privacidade
        </v-btn>
      </v-col>

      <v-col cols="12" md="4" class="alinhadoM">
        <v-img width="50" contain src="./assets/abareBranco.png"></v-img>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" timeout="-1" text="black">
      <span style="font-family: 'GPMetro light'; font-size: 18px">
        Ao aceitar, você concorda com nossa <a @click="politica = !politica"  >Política de Privacidade</a>
      </span>
        <v-btn color="#F08614" text v-bind="attrs" @click="snackbar = false" style="font-weight: 800;">
          EU ACEITO
        </v-btn>
    </v-snackbar>

  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    snackbar: true,
    dialog: false,
    politica: false,
    contato: { nome: "", email: "", telefone: "", cpf: "", orgao: "" },
    items: ["INSS", "SPPREV", "SIAPE", "MARINHA/EXÉRCITO", "OUTROS"],
    itemsENG: [
      { name: "PROMOÇÕES", path: "Home" },
      { name: "QUEM SOMOS", path: "SobreRibus" },
      { name: "DEPOIMENTOS", path: "Equipe" },
      { name: "POR QUE SOMOS IMBATÍVEIS", path: "Equipe" },
    ],
    tagsGtm: {
      "empresarial":"GTM-58W7KWQ",
      "cafezinho":"GTM-WJBPDNC",
      "lp-video":"GTM-K6P9G9M",
      "influencer":"GTM-562XJPM",
    },
    idTag:""
  }),
  mounted() { 

  },
  watch:{
    "$route.name":{
      handler:function(nValue) {
        let page = nValue
        let id = this.tagsGtm[page]
        console.log({page,id})
        if (id) { 
          this.idTag = this.tagsGtm[page]
          this.enableGoogleAnalytics()
        }
      },
      deep:true,

    }
  },
  methods: {
    enviarFormulario() {
      fetch("https://gestao.abare.cloud/items/leads", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ id_formulario: 62, lead: this.contato }),
      })
        .then((response) => response.text())
        .then((data) => {
          window.console.log(data);
          // alert("contato enviado com sucesso");
          this.dialog = false;
          this.snackbar = true;
          //this.enviarEmail()
        });
    },

    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },

    async enableGoogleAnalytics() {
        // var googleAnalyticsId = await loadGtmId(); // GTM-XXXXXXX
        this.activateGtm(window, document, 'script', 'dataLayer', this.idTag);
    },
    activateGtm(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    },

    enviarEmail() {
      fetch("https://gestao.abare.cloud/email/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          template: "d-ff5fb7201c8543bca07f7801d2bf5a9c",
          email: "anacarolina@ezeus.com.br",
          strSite: "clubconsig.com.br",
          lead: {
            nome: this.contato.nome,
            email: this.contato.email,
            telefone: this.contato.telefone,
            mensagem: `CPF: ${this.contato.cpf} \n Orgão: ${this.contato.orgao} `,
          }
        }),
      })
        .then((response) => response.text())
        .then(() => {
          this.$router.push("obrigado");

          alert("email enviado!")
        });
    },
  },

  computed: {
    isHome() {
      return this.$route.name == "inicial";
    },
    isLp() {
      return this.$route.name == "lp";
    },


    intBreakPoint() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0;
        case "sm":
          return 1;
        case "md":
          return 2;
        case "lg":
          return 3;
        case "xl":
          return 4;
        default:
          return 0;
      }
    },
  },
};
</script>

<style>
@media (min-device-width: 360px) and (max-device-width: 640px) {
  .v-slide-group__prev {
    min-width: 5px !important;
  }

  .v-slide-group__next {
    min-width: 5px !important;
  }
}

.alinhadoM {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}



html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'GPMetro Light';
  src: url('./assets/fonts/GPMetro Light.ttf') format('truetype');
  font-display: swap;
  font-style: light;
  font-stretch: normal;
}

@font-face {
  font-family: 'GPMetro Bold';
  src: url('./assets/fonts/GPMetro Bold.ttf') format('truetype');
  font-display: swap;
  font-style: bold;
  font-stretch: normal;
}

@font-face {
  font-family: 'GPMetro Medium';
  src: url('./assets/fonts/GPMetro Medium.ttf') format('truetype');
  font-display: swap;
  font-style: medium;
  font-stretch: normal;
}

.v-image {
  height: 100% !important;
}

@media (min-device-width: 360px) and (max-device-width: 731px) {
  .alignMobile {
    align-items: center !important;
  }

  .DisableMobile {
    display: none !important;
  }

  .padMobile {
    padding: 50px 20px !important;
  }
}

.alinhadoM {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
}

.btnMenu {
  font-family: "Poppins" !important;
  font-weight: 300 !important;
  box-shadow: none !important;
  border: none !important;
  background-color: transparent !important;
}

.btnMenu:hover {
  color: #769d4a !important;
  font-family: "Poppins" !important;
  font-weight: 300 !important;
  box-shadow: none !important;
  border: none !important;
}
</style>
